:root {
  --text-border-width: 1px;
  --text-border-color: #278094;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  background-color: #003044;
}

a:link,
a:visited {
  color: #FFF;
  text-decoration: none;
}

a:hover,
a:active {
  color: #3258DD;
  text-decoration: none;
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  z-index: 1;
}

.logo-wrapper {
  padding: 30px 0px 30px 30px;
  transition: opacity 0.5s ease;
}

.logo-hidden {
  opacity: 0;
}

.logo-img {
  height: 60px;
}

.menu-wrapper {
  padding: 45px 30px 45px 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

@media (max-height: 768px) {
  header {
    height: 90px;
  }

  .logo-img {
    height: 30px;
  }
}

.section {
  background-size: cover;
  background-position: center;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 18px;
  display: flex !important;
  flex-direction: column;
  overflow-x: hidden !important;
}

.section-about {
  background: url('../public/images/bg-about.png') no-repeat center center/cover;
}

.section-product {
  background: url('../public/images/bg-product.png') no-repeat center center/cover;
}

.section-catalogue {
  background: url('../public/images/bg-catalogue.jpg') no-repeat center center/cover;
}

.section-features {
  /* background: url('../public/images/bg-features.jpg') no-repeat center center/cover; */
}

.bg-video {
  position: absolute;
  top: 300svh;
  left: 0px;
  width: 100vw;
  height: 100svh;
  object-fit: cover;
  z-index: -1;
}

.section-contacts {
  /* background: url('../public/images/bg-contacts.jpg') no-repeat center center/cover; */
}

.about-wrapper,
.product-wrapper,
.catalogue-wrapper,
.features-wrapper,
.contacts-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0px 90px;
  width: 100%;
  overflow: hidden;
}


/* about CSS */
.about-badge-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin-left: 90px;
  margin-right: 90px;
  margin-top: 120px;
  width: 100%;
  min-height: 36px;
  overflow: hidden;
}

.about-badge {
  font-size: 12px;
  line-height: 12px;
  padding: 12px 0px 12px 12px;
  text-align: left;
  color: #FFF;
  min-width: 99px;
  max-width: 99px;
  margin-right: 639px;
  border-left: 1px solid #FFF;
  mask: linear-gradient(transparent 5%, black 35%, black 65%, transparent 95%);
  background-color: #3258DD;
}

@media (max-height: 768px) {
  .about-badge-wrapper {
    margin-top: 90px;
  }
}

.about-message {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: flex-start;
  width: 100%;
  padding: 60px 30px;
  min-width: 420px;
  max-width: 420px;
  mask: linear-gradient(transparent 5%, black 20%, black 80%, transparent 95%);
  backdrop-filter: blur(3px);
}

.about-key-message {
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: bold;
  color: #FFF;
}

.about-key-message-details {
  font-size: 18px;
  line-height: 30px;
  color: #FFF;
  margin-bottom: 12px;
}

.about-image {
  min-width: 270px;
  /* width: 270px; */
  max-width: 270px;
}

@media (max-width: 768px) {
  .about-badge {
    margin-right: auto;
  }

  .about-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 30px;
  }

  .about-message {
    align-content: flex-start;
    min-width: 270px;
    max-width: 270px;
  }
}



/* product CSS*/
.product-badge-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin-left: 90px;
  margin-right: 90px;
  margin-top: 120px;
  width: 100%;
  min-height: 36px;
  overflow: hidden;
}

.product-badge {
  font-size: 12px;
  line-height: 12px;
  padding: 12px 0px 12px 12px;
  text-align: left;
  color: #FFF;
  min-width: 99px;
  max-width: 99px;
  margin-right: 639px;
  border-left: 1px solid #FFF;
  mask: linear-gradient(transparent 5%, black 35%, black 65%, transparent 95%);
  background-color: #276c19;
}

@media (max-height: 768px) {
  .product-badge-wrapper {
    margin-top: 90px;
  }
}

.product-message {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  min-width: 510px;
  max-width: 510px;
  padding: 60px 30px;
  mask: linear-gradient(transparent 5%, black 20%, black 80%, transparent 95%);
  backdrop-filter: blur(6px);
}

.product-key-message {
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: bold;
  color: #FFF;
}

.product-key-message-details {
  font-size: 18px;
  line-height: 30px;
  color: #FFF;
  margin-bottom: 12px;
}

.product-image {
  min-width: 180px;
  max-width: 180px;
}

@media (max-width: 768px) {
  .product-badge {
    margin-right: auto;
  }

  .product-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 30px;
  }

  .product-message {
    min-width: 270px;
    max-width: 270px;
    align-content: flex-start;
    mask: linear-gradient(transparent 3%, black 15%, black 85%, transparent 97%);
  }
}


/* catalogue CSS*/
.catalogue-badge-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin-left: 90px;
  margin-right: 90px;
  margin-top: 90px;
  width: 100%;
  min-height: 36px;
  overflow: hidden;
}

.catalogue-badge {
  font-size: 12px;
  line-height: 12px;
  padding: 12px 0px 12px 12px;
  text-align: left;
  color: #FFF;
  min-width: 99px;
  max-width: 99px;
  margin-right: 639px;
  border-left: 1px solid #FFF;
  mask: linear-gradient(transparent 5%, black 35%, black 65%, transparent 95%);
  background-color: #3258DD;
}

@media (max-height: 768px) {
  .catalogue-badge-wrapper {
    margin-top: 90px;
  }
}

.catalogue-message {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  min-width: 510px;
  max-width: 510px;
  padding: 60px 30px 30px 30px;
  mask: linear-gradient(transparent 5%, black 20%, black 80%, transparent 95%);
  backdrop-filter: blur(6px);
}

.catalogue-key-message {
  font-size: 30px;
  font-weight: bold;
  color: #FFF;
}

.catalogue-image {
  min-width: 180px;
  max-width: 180px;
  mask: linear-gradient(transparent 5%, black 20%, black 80%, transparent 95%);
  backdrop-filter: blur(6px);
}

@media (max-width: 768px) {
  .catalogue-badge {
    margin-right: auto;
  }

  .catalogue-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 30px;
  }

  .catalogue-message {
    min-width: 270px;
    max-width: 270px;
    padding: 15px 30px 15px 30px;
    align-content: flex-start;
    mask: linear-gradient(transparent 3%, black 15%, black 85%, transparent 97%);
    backdrop-filter: blur(6px);
  }
}

/* slider related CSS */
.rwa-slider-wrapper {
  min-width: 750px;
  max-width: 750px;
  align-content: flex-start;
  /* padding: 60px; */
  display: grid;
  mask: linear-gradient(transparent -5%, black 5%, black 90%, transparent 105%);
  backdrop-filter: blur(6px);
}

.swiper {
  min-height: 400px;
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #971a1a; */
  mask: linear-gradient(transparent -5%, black 5%, black 90%, transparent 105%);
  backdrop-filter: blur(6px);

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 768px) {
  .rwa-slider-wrapper {
    min-width: 330px;
    max-width: 330px;
    align-content: flex-start;
    padding: 0px 0px 0px 0px;
  }

  .swiper {
    min-width: 330px;
    max-width: 330px;
    min-height: 450px;
    width: 100%;
    height: 100%;
    margin-left: 0 !important;
  }
}


/* features CSS */
.features-badge-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin-left: 90px;
  margin-right: 90px;
  margin-top: 120px;
  width: 100%;
  min-height: 36px;
  overflow: hidden;
}

.features-badge {
  font-size: 12px;
  line-height: 12px;
  padding: 12px 0px 12px 12px;
  text-align: left;
  color: #FFF;
  min-width: 99px;
  max-width: 99px;
  margin-left: 549px;
  margin-right: 90px;
  border-right: 1px solid #FFF;
  border-left: 0px;
  mask: linear-gradient(transparent 5%, black 35%, black 65%, transparent 95%);
  background-color: #FF3A4C;
}

@media (max-height: 768px) {
  .features-badge-wrapper {
    margin-top: 90px;
  }
}

.features-message {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-start;
  width: 100%;
  min-width: 390px;
  max-width: 390px;
  padding: 60px 30px;
  mask: linear-gradient(transparent 5%, black 20%, black 80%, transparent 95%);
  backdrop-filter: blur(3px);
}

.features-key-message {
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: bold;
  color: #FFF;
}

.features-key-message-details {
  font-size: 21px;
  /* line-height: 30px; */
  font-variant-caps: all-small-caps;
  color: #FFF;
  margin-bottom: 12px;
}

.features-key-message-details-2 {
  font-size: 15px;
  /* line-height: 30px; */
  font-variant-caps: normal;
  color: #FFF;
  padding-left: 18px;
}

.features-image {
  min-width: 300px;
  max-width: 300px;
}

@media (max-width: 768px) {
  .features-badge {
    margin-right: auto;
    margin-left: 0;
    border-right: 0px;
    border-left: 1px solid #FFF;
  }

  .features-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0px 30px;
  }

  .features-message {
    min-width: 270px;
    max-width: 270px;
    align-content: flex-start;
    mask: linear-gradient(transparent 3%, black 15%, black 85%, transparent 97%);
  }
}



/* contacts CSS */
.contacts-badge-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin-left: 90px;
  margin-right: 90px;
  margin-top: 120px;
  width: 100%;
  min-height: 36px;
  overflow: hidden;

}

.contacts-badge {
  font-size: 12px;
  line-height: 12px;
  padding: 12px 0px 12px 12px;
  text-align: left;
  color: #FFF;
  min-width: 69px;
  max-width: 69px;
  margin-right: 669px;
  border-left: 1px solid #FFF;
  mask: linear-gradient(transparent 5%, black 35%, black 65%, transparent 95%);
  background-color: #3258DD;
}

@media (max-height: 768px) {
  .contacts-badge-wrapper {
    margin-top: 90px;
  }
}

.contacts-follow-us {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: flex-end;
  width: 100%;
  min-width: 390px;
  max-width: 390px;
  padding: 0px 30px;
}

.contacts-contact-us {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  min-width: 270px;
  max-width: 270px;
  padding-left: 30px;
}

.contacts-logo-img {
  width: 100%;
  margin-top: 33px;
  margin-bottom: 18px;
}

.contacts-name {
  font-size: 21px;
  line-height: 33px;
  font-weight: bold;
  color: #A4A9B4;
  margin: 18px 0px 0px 0px;
}

.contacts-flag {
  height: 15px;
  margin: 0px 6px 1px 0px;
}

.contacts-phone {
  font-size: 21px;
  line-height: 33px;
  color: #A4A9B4;
  margin: 0px 0px 6px 0px;
}

.contacts-social {
  margin: 0px 0px 6px 0px;
}

@media (max-width: 768px) {
  .contacts-badge {
    margin-right: auto;
  }

  .contacts-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0px 30px;
  }

  .contacts-follow-us,
  .contacts-contact-us {
    flex-direction: column;
    width: 100%;
  }

  .contacts-follow-us {
    align-content: flex-start;
  }

  .contacts-logo-img {
    width: 60%;
  }
}


/* other */
.motion-image {
  width: 300px;
  height: 200px;
}

.card {
  flex: 0 0 calc((100% - 4rem) / 3);
  /* background: rgba(255, 255, 255, 0.2); */
  /* backdrop-filter: blur(10px); */
  /* -webkit-backdrop-filter: blur(10px); */
  /* border-radius: 12px; */
  padding: 15px 15px 30px 15px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  /* transition: transform 0.3s ease; */
  /* border: 1px solid rgba(255, 255, 255, 0.2); */
  mask: linear-gradient(transparent -5%, black 5%, black 90%, transparent 105%);
  backdrop-filter: blur(6px);

}

.card-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 6px;
  color: #1a1a1a;
}

.card-geography {
  font-size: 15px;
  margin-right: 20px;
}

.card-go-live {
  font-size: 15px;
  color: #222;
  margin-bottom: 1rem;
}

.card-i1-link {
  font-size: 18px;
  margin-left: 9px;
  color: #1a1a1a !important;
}

.card-description {
  font-size: 15px;
  color: #FFF;
  margin-bottom: 1rem;
}

.metrics-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.5rem;
  margin-top: 1rem;
}

.metric {
  text-align: center;
  padding: 0.7rem;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.metric-value {
  font-size: 0.8rem;
  font-weight: 600;
  color: #042f64;
}

.metric-label {
  font-size: 0.7rem;
  color: #000;
}

@media (max-width: 768px) {
  .metrics-grid {
    gap: 6px;
  }

  .metric {
    padding: 0.2rem;
  }

  .metric-label {
    font-size: 0.6rem;
  }
}

.returns-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 0.5rem;
  margin-top: 1rem;
}

.return {
  text-align: center;
  padding: 0.5rem;
  background: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(6px);
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.return-value {
  font-size: 0.9rem;
  font-weight: 600;
  color: #042f64;
}

.return-label {
  font-size: 0.7rem;
  color: #000;
}

@media (max-width: 768px) {
  .card {
    flex: 0 0 100%;
    margin: 0 auto;
    padding-left: 30px;
  }
}

.slider {
  padding: 1rem 0;
}

.slider-button {
  width: 50px;
  height: 50px;
  font-size: 1.5rem;
}